import * as React from "react";
import Box from "@mui/material/Box";
import { LoopyRunner } from "../LoopyRunner";
import { Link } from "react-router-dom";

export function MobileAppBar() {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Link
        to="/"
        style={{
          textDecoration: "none",
          color: "white",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        aria-label="Go to the homepage"
      >
        <LoopyRunner
          sx={{ display: { xs: "flex", flexGrow: 1, fontSize: "8rem" } }}
        />
      </Link>
    </Box>
  );
}
