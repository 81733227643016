import { ReactComponent as LoopyLogoSvg } from '../assets/logo.svg';
import {  SvgIconProps } from '@mui/material';

export const LoopyLogo: React.FC<SvgIconProps> = (props) => {
  return (
    <LoopyLogoSvg
      id='logo'
      {...props}
    />
  );
};
