import * as React from "react";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import { Link } from "@mui/material";

export function Footer() {
  return (
    <Paper elevation={4} sx={{ p: 2 }} square>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          Copyright {new Date().getFullYear()} © Loopy Studios, LLC.
          <Link href="/employees" underline="hover">
            Employees
          </Link>
        </Box>
      </Box>
    </Paper>
  );
}
