import React from "react";
import { Typography, Box } from "@mui/material";

export default function HomePage() {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Typography variant="body1" paragraph>
        This website is operated by Loopy Studios, LLC., which is a company
        registered in Florida (registration number pending) whose registered
        office is to be determined.
      </Typography>
    </Box>
  );
}
